<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="modal-invoice-preview"
    ref="refModal"
    :title="t('Chi tiết hóa đơn')"
    hide-footer
    size="lg"
    scrollable
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="showModal"
    @hidden="resetModal"
  >
    <!-- Body -->

    <div
      v-if="invoiceHtml || pdfUrl"
      class="mt-50"
    >
      <b-button
        v-if="showHtml"
        variant="success"
        @click="printInvoice"
      >
        <feather-icon
          icon="PrinterIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ t('In hóa đơn') }}</span>
      </b-button>
      <b-dropdown
        left
        :text="t('Gửi hóa đơn')"
        variant="primary"
        class="ml-50"
      >
        <b-dropdown-item v-clipboard:copy="previewUrl">
          {{ t('Sao chép link hóa đơn') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendInvoiceViaApp">
          {{ t('Gửi thông báo qua App') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendInvoiceViaZaloZNS">
          {{ t('Gửi tin nhắn Zalo OA (ZNS)') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendInvoiceViaZaloNormal">
          {{ t('Gửi tin nhắn Zalo BOT') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendInvoiceViaEmail">
          {{ t('Gửi email') }}
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        left
        :text="t('Tải hóa đơn')"
        variant="info"
        class="ml-50"
      >

        <b-dropdown-item @click="downloadInvoicePDF">
          {{ t('Tải xuống PDF') }}
        </b-dropdown-item>
        <b-dropdown-item @click="downloadInvoiceImage">
          {{ t('Tải xuống ảnh') }}
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        v-if="itemLocal.remain !== 0 && $can('pay', 'invoice')"
        class="ml-50"
        variant="warning"
        @click="$emit('pay-invoice')"
      >
        <feather-icon
          icon="CreditCardIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ t('Thu tiền') }}</span>
      </b-button>
      <b-button
        v-if="!itemLocal.approve && $can('approve', 'invoice') && itemLocal.type !== '8'"
        variant="info"
        class="ml-50"
        @click="$emit('approve-invoice')"
      >
        <feather-icon
          icon="CheckSquareIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ t('Duyệt') }}</span>
      </b-button>
      <b-button
        v-if="itemLocal.approve && itemLocal.paid === 0 && $can('approve', 'invoice') && itemLocal.type !== '8'"
        variant="secondary"
        class="ml-50"
        @click="$emit('decline-invoice')"
      >
        <feather-icon
          icon="XIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ t('Bỏ duyệt') }}</span>
      </b-button>

    </div>

    <div class="d-flex justify-content-center my-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>
    <div
      v-if="invoiceHtml && showHtml && !isLoading"
      class="border rounded border-secondary my-2"
      v-html="invoiceHtml"
    />

    <iframe
      v-if="pdfUrl && !showHtml && !isLoading"
      :src="pdfUrl"
      style="width: 100%; height: 80vh; border: none;"
    />

  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue';
import { required } from '@validations';
import useInvoicePreviewModal from './useInvoicePreviewModal';

export default {
  components: {
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props) {
    const {
      itemLocal,
      resetItemLocal,
      resetModal,
      invoiceHtml,
      showHtml,
      pdfUrl,
      isLoading,
      previewUrl,
      fetchInvoiceHtml,
      showModal,
      printInvoice,
      downloadInvoicePDF,
      downloadInvoiceImage,
      sendInvoiceViaZaloZNS,
      sendInvoiceViaZaloNormal,
      sendInvoiceViaEmail,
      sendInvoiceViaApp,
      t,
    } = useInvoicePreviewModal(props);

    return {
      itemLocal,
      resetItemLocal,
      resetModal,
      invoiceHtml,
      showHtml,
      pdfUrl,
      isLoading,
      previewUrl,
      fetchInvoiceHtml,
      showModal,
      printInvoice,
      downloadInvoicePDF,
      downloadInvoiceImage,
      sendInvoiceViaZaloZNS,
      sendInvoiceViaZaloNormal,
      sendInvoiceViaEmail,
      sendInvoiceViaApp,
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
